@import 'src/utils/styles/_imports';

$mobile-header-height: 56px;

.saps-routing {
  width: 100%;
  height: 100%;

  &.is-mobile {
    &.is-search-results {
      height: calc(100% - (#{$mobile-header-height}));
      margin-top: $mobile-header-height;
    }
  }
}
