@import 'src/utils/styles/_imports';

.ct-homepage-main2 {
  min-height: 100vh;
  overflow-y: auto;
  background-color: $white;
  display: flex;
  flex-direction: column;

  @media (max-width: $media-md) {
    background: $brand-blue-50;
  }

  .homepage-grid {
    flex-grow: 1;
  }

  .desktop-sidebar {
    background: $brand-blue-50;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      font-weight: 700;
      font-size: 2.625rem;
      max-width: 20rem;
    }
    .subheader {
      margin-top: 1.6rem;
      max-width: 20rem;
      font-size: 1.125rem;
      color: $gray-800;
    }
  }

  .mobile-sidebar {
    text-align: left;

    .header {
      max-width: 16rem;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.625rem;
    }

    .subheader {
      margin-top: 1rem;
      margin-bottom: 2rem;
      color: $gray-800;
      line-height: 1.5rem;
    }
  }

  @media (max-width: $media-sm) {
    // this fixes the issue on ios where the homepage was scrolled off page
    // and causes major mis-aligned with buttons on the educational modal
    min-height: initial;
  }

  .ct-hompage-header {
    top: 0;
    position: absolute;
    padding: 1rem 1.5rem;
    display: flex;

    @media (max-width: $media-sm) {
      justify-content: center;
    }

    .saps-logo-svg {
      min-height: rem-calc(30);
    }
  }

  .ct-homepage-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $homepage-content-height;
    padding-top: 8rem;
    @media (max-width: $media-sm) {
      min-height: 100vh;
    }
    height: 100%;
    max-width: 31.25rem;

    .sm-header {
      align-self: center;
      text-align: center;

      @media (max-width: $media-sm) {
        font-size: 0.875rem;
      }
    }

    .big-header {
      align-self: center;
      text-align: center;
      font-size: 1.375rem;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: rem-calc(55);

      @media (max-width: $media-sm) {
        font-size: 1.5rem;
      }
    }

    .ps-example {
      background: $gray-50;
      margin-top: 4.25rem;
      padding: 1.5rem 2.625rem;
      border-radius: rem-calc(10);
      display: flex;
      flex-direction: column;
      visibility: hidden; // still want this to take up space

      .example-text {
        max-width: rem-calc(580);
        margin-top: 0.5rem;
        font-style: italic;
        line-height: rem-calc(28);
      }
    }

    .ct-search-group {
      margin-bottom: 2rem;
      margin-top: 4rem;
    }
  }

  .ct-shortcuts {
    padding-top: 2rem;
  }

  .footer-container {
    padding: 1rem 1.5rem;
    font-size: 0.75rem;
    color: $gray-700;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .footer-content {
      display: flex;

      .footer-links {
        flex-grow: 1;

        a {
          padding-right: rem-calc(15);
          color: $gray-700;
          text-decoration: none;
        }
      }
    }
  }

  .auth-alert {
    padding-top: 1rem
  }

  .resend-button {
    border: 0;
  }
}

.ct-modal-description {
  text-align: center;
}
