@import 'src/utils/styles/_imports';

.ct-sidebar-container {
  .result-sidebar-accordion-root {
    padding-top: 1rem;
    background: $white;
    box-shadow: none;

    &:before {
      display: none
    }
  }

  .result-sidebar-accordion-details-root {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .result-sidebar-accordion-summary-root {
    flex-direction: row-reverse;
    min-height: 2rem;
  }

  .result-sidebar-accordion-summary-content {
    margin-bottom: 0;
    margin-top: 0;
  }
}