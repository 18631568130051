@import 'src/utils/styles/_imports';

.ct-results-filters {
  column-gap: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0;

  .popover-filters-container {
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;

    .popover-filters-container-space {
      flex-grow: 1;
    }

    .ct-checkbox-tri-label {
      font-size: 0.875rem;

      .meta-label {
        font-size: 0.75rem;
      }
    }
  }
}
