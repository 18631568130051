@import 'src/utils/styles/_imports';

.ct-error-results {
  padding: 2rem 1rem 0;
  display: flex;
  justify-content: center;

  .ct-error-results-content {
    max-width: rem-calc(400);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .ct-no-result-title {
      margin-bottom: 1rem;
    }

    span {
      line-height: 1.25rem;
    }

    .contact-container {
      padding-top: 1rem;

      .contact-support {
        color: $brand-blue-500;
      }
    }
  }
}
