@import 'src/utils/styles/_imports';

.ct-mobile-toolbar.ct-fixed-toolbar {
  .logo-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .casetext-logo {
      width: 8.5rem;
    }
  }

  .search-container {
    align-self: stretch;
    width: 100%;

    .ct-search-group {
      box-shadow: none;
      border: none;
      height: 100%;
      width: 100%;

      button {
        border-radius: 0;
        border: none;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}


