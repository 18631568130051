@import 'src/utils/styles/_imports';

.share {
  display: contents;

  .share-heading {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .share-link-text {
    background: $gray-300;
    border-radius: 0.25rem;
    display: flex;
    height: 3.438rem;
    justify-content: center;
    word-break: break-all;
    max-width: rem-calc(466);
    width: 100%;
    box-sizing: border-box;

    :hover {
     color: $black;
    }
  }
}