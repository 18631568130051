@import 'src/utils/styles/_imports';

svg.result-treatment-box {
    position: relative;
    top: rem-calc(5);
    width: rem-calc(16);
    height: rem-calc(16);
    border-radius: rem-calc(2);
    margin-top: rem-calc(1);
    margin-right: rem-calc(8);
    font-size: 1rem;
    z-index: $base-z-index;

    &.negative {
        color: $red-500;
        background: $red-500;
    }
    &.warning-distinguish-question-comparison {
        color: $yellow-500;
        background: $yellow-500;
    }
    &.modified-caution {
        color: $orange-500;
        background: $orange-500;
    }
    &.codified-positive {
        color: $green-500;
        background: $green-500;
    }
}
