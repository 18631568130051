@import 'src/utils/styles/_imports';

.ct-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0 1rem;
  opacity: 0;
  animation: fadeIn 1.5s ease-in 0.5s forwards;

  &.no-delay {
    animation-delay: 0s;
  }

  .loader-container {
    padding-top: 0.25rem;
    width: rem-calc(200);
  }
}
