@import 'src/utils/styles/_imports';

.ct-no-results {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .ct-no-result-title {
    margin-bottom: 1rem;
  }

  .ct-no-results-list {
    list-style-type: none;
    margin: 1rem 0 0 0;
    padding: 0;

    li {
      text-align: center;

      span {
        line-height: 1.25rem;
      }

      .search-btn {
        padding: 0;
        font-weight: normal;
        text-decoration: underline;

        &:hover {
          background-color: transparent;
        }
      }
    }

    li::before {
      content: "\2022";
      color: $gray-700;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
