@import 'src/utils/styles/_imports';

.ct-gen-modal {

  .close-btn {
    position: absolute;
    top: rem-calc(12);
    right: rem-calc(24);
    z-index: 5;
  }

  .search-ed-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem-calc(46);

    @media (max-width: $media-sm) {
      padding-bottom: 0;
      padding-top: rem-calc(61);
      padding-bottom: rem-calc(17);
    }
  }

  .share-modal {
    @include gen-container();
    padding: rem-calc(55) rem-calc(103) rem-calc(48) rem-calc(103);

    @media (max-width: $media-sm) {
      padding: rem-calc(48);
    }
  }

  .signin-modal {
    @include gen-container();
    position: relative;
    padding: rem-calc(48);
  }
}