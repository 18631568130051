@import 'src/utils/styles/_imports';

.ct-ps-card {
  background: $brand-blue-50;
  border-radius: .312rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;

  &.ed {
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 3rem 5rem;
    padding-bottom: 2rem;

    .card-body {
      margin-top: 1.3rem;
      color: $gray-800;
      max-width: 31.5rem;
      line-height: 1.625rem;
    }

    @media (max-width: $media-sm) {
      font-size: 0.875rem;
      padding: 3rem 1.875rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }

  &.success {
    align-items: center;
    margin-top: rem-calc(28.96);
    margin-bottom: rem-calc(49);
    padding: rem-calc(39) rem-calc(49) rem-calc(37) rem-calc(49);

    .card-body {
      margin-top: 0.563rem;
      color: $gray-800;
      line-height: 1.625rem;
    }
  }

  .close-btn {
    position: absolute;
    right: 1.3rem;
    top: 0.5rem;
  }

  .card-header {
    font-weight: 400;
    color: $gray-900;
    font-size: 1.5rem;

    @media (max-width: $media-sm) {
      font-size: 1.25rem;
      padding: 0 2rem;
    }
  }

  .card-cta {
    margin-top: 2.25rem;
  }
}