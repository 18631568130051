@import 'src/utils/styles/_imports';

.ct-filter-popper,
.content-type-modal {
  .ct-filter-popper-paper {
    .ct-results-sidebar {
      padding: 0;
    }
  }

  .filter-modal-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    padding-bottom: 0;

    .modal-header-text {
      flex: 1;
    }
  }
}
