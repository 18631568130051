@import 'src/utils/styles/_imports';

html, body {
  height: 100%;
}

body {
  font-family: $inter-font;
  font-size: 1rem;
  margin: 0;

  #casetext-admin-app {
    width: 100%;
    height: 100%;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .d-none {
    display: none;
  }

  .d-block {
    display: block;
  }

  .d-flex {
    display: flex;
  }

  .scrollable-y {
    overflow-y: auto;
  }
}
