@import 'src/utils/styles/_imports';

.ct-search-header {
  align-items: center;
  background-color: $white;
  border-bottom: rem-calc(1) solid $gray-300;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem;
  z-index: $header-z-index;
  max-height: rem-calc(41);
  position: relative;

  .saps-logo-svg {
    width: rem-calc(150);
    position: absolute;
    top: 0.7rem;
    left: 1rem;
  }
  
  .share-btn {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }

  .ct-search-input {
    max-width: rem-calc(700);
    position: relative;
    width: 100%;

    &.wide-search {
      margin: 0;
      max-width: rem-calc(868);
    }

    &.jx-open {
      top: 0;
    }

  }
}