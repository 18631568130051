@import 'src/utils/styles/_imports';

.teaser {
  @include gen-container();

  .teaser-title {
    font-weight: rem-calc(400);
    margin-top: rem-calc(75);
    margin-bottom: rem-calc(50);
    max-width: rem-calc(500);
    text-align: center;

    @media (max-width: $media-sm) {
      margin: rem-calc(30) rem-calc(30) rem-calc(50) rem-calc(30)
    }
  }

  .teaser-logo {
    height: rem-calc(33);
    margin-top: rem-calc(25);

    @media (max-width: $media-sm) {
      margin-top: rem-calc(30);
    }
  }

  .teaser-callouts-box {
    @include ct-callout-box();
    padding: rem-calc(40);
    margin-top: rem-calc(70);

    .ct-callouts-icon {
      padding-right: rem-calc(10);
    }

    .teaser-callouts-container {
      padding-bottom: 2rem;

      &:last-child {
        padding-bottom: 0;
      }

      @media (max-width: $media-sm) {
        padding-bottom: rem-calc(24);
      }
    }

    .teaser-callouts {
      display: flex;
      flex-direction: row;
      padding-bottom: 0.25rem;
    }

    .teaser-callouts-sub {
      display: block;
      padding-left: rem-calc(35);
    }

    @media (max-width: $media-sm) {
      margin-top: rem-calc(60);
      padding: 1rem;
    }
  }

  @media (max-width: $media-sm) {
    padding: 0;
  }
}