@import 'src/utils/styles/_colors';
@import 'src/utils/styles/_functions';
@import 'src/utils/styles/_variables';

@mixin break-long-words() {
  // avoid overflows (caused by long words)
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ct-callout-box() {
  align-items: flex-start;
  background: $brand-blue-50;
  border-radius: 0.625rem;
}

@mixin content-container() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: $homepage-content-height;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

@mixin gen-container() {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.is-mobile {
    padding: 2rem 1.5rem;
  }
}

@mixin highlight-base() {
  font-style: normal;
  font-weight: inherit;
  padding: rem-calc(1) rem-calc(2);
}

@mixin highlight-blue() {
  @include highlight-base();
  background: $bright-blue-100;
  box-shadow: 0 rem-calc(1) 0 $bright-blue-500;
}
