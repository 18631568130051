@import 'src/utils/styles/_imports';

.ct-search-input-w-suggestions {
  align-items: flex-start;
  border-bottom: rem-calc(1) solid $gray-300;
  display: flex;
  justify-content: flex-start;
  
  &.is-mobile {
    padding: 0;
    align-items: center;
  }

  &.search-open {    
    &.is-mobile {
      padding: 0;
      flex-direction: column;
    }
  }

  .ct-search-bar-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .ct-search-input-container-mobile {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0 rem-calc(1) 0;
    width: 100%;
    &.search-open,
    &.is-serp {
      border-bottom: rem-calc(1) solid $gray-300;
    }


    .ct-icon-container-mobile {
      color: $gray-500;
      flex-grow: 0;
      flex-shrink: 0;
      padding: rem-calc(11) 0.5rem 0.5rem 1rem;
      
      &.is-serp {
        &.search-open {
          margin-top: rem-calc(5);
        }
      }
    }
    .ct-search-text-container-mobile {
      display: flex;
      padding: 0.375rem 0;
      width: 100%;
    }
  }

  .ct-search-input-back-container {
    border-right: rem-calc(1) solid $gray-300;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.25rem;
  }

  .ct-icon-container,
  .ct-jx-button-container {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.is-mobile {
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      
      &.search-open {
        width: calc(100% - 0.5rem);
        padding-right: 0;
        &.is-serp {
          border-bottom: rem-calc(1) solid $gray-300;
        }
      }
  
      .ct-jx-button {
        padding-bottom: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        &:hover {
          background-color: transparent;
          color: $brand-blue-600;
        }
      }
    }

    .ct-jx-select {
      font-weight: 400;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }

    .ct-jx-button {
      padding-bottom: 0.25rem;
      padding-right: 0.25rem;
      padding-top: 0.25rem;

      &:hover {
        background-color: transparent;
        color: $brand-blue-600;
      }
    }
  }

  .ct-icon-container {
    padding: 0.375rem 0.5rem;
    color: $gray-700;
  }

  .ct-text-area-container {
    flex-grow: 1;
    &.is-mobile {
      &.is-serp {
        &.search-open {
          margin-top: rem-calc(5);
        }
      }
    }
  }

  .ct-search-text-area {

    &.is-large {
      textarea {
        height: rem-calc(69) !important;
      }
    }

    .MuiInput-root {
      padding: 0.25rem;

      textarea {
        color: $gray-900;
        font-family: $inter-font;
        font-size: 0.875rem;
        overflow: hidden;
      }

      &:hover,
      &:focus,
      &:active {
        &::before,
        &::after {
          border-bottom: none;
        }
      }

      &::before,
      &::after {
        border-bottom: none;
      }
    }
  }
}

.ct-search-suggestions-container {
  padding: 0.5rem;

  &.is-mobile {
    padding: 0;
    width: 100%;
    &.is-serp {
      border-bottom: rem-calc(1) solid $gray-300;
    }
  }

  .ct-search-suggestions-list {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ct-suggestion-list-item {
    border-radius: 0.25rem;
    font-size: 0.875rem;
    overflow-x: wrap;
    padding: 0.5rem;
    white-space: wrap;
    &.ct-search {
      overflow-x: hidden;
      white-space: nowrap;
    }

    .ct-suggestion-list-icon {
      width: 1.5rem;
    }

    &.ct-suggestion-selected,
    &:hover {
      background-color: $brand-blue-50;

      .ct-suggestion-list-icon {
        color: $brand-blue-500;

        &.chevron {
          visibility: visible;
        }
      }
    }

    .ct-suggestion-list-icon {
      &.chevron {
        visibility: hidden;
        align-self: center;
      }
    }

    .ct-suggestion-list-item-text-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 0.5rem;
      overflow: auto;

      &.ct-search {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ct-suggestion-list-item-text {
        line-height: rem-calc(20);

        &.ct-search {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        &.ct-db-search {
          padding-left: 0.5rem;
        }

        em {
          color: $brand-blue-500;
          font-style: normal;
          font-weight: 600;
          text-decoration-line: underline;
        }
      }
    }
  }
}
.ct-white-rounded-corners {
  background: $ct-search-white;
  &.is-serp {
    &.is-mobile {
      border-radius: 0rem;
    }

    &:not(.is-mobile):not(.search-open) {
      border-radius: 3rem;
    }
  }
  border-radius: .25rem;
}

.search-button-container {
  text-align: left;
  @media (min-width: $media-md) {
    text-align: center;
  }
}
