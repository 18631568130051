@import 'src/utils/styles/_imports';

.ct-error-modal {
  position: relative;

  .MuiDialog-container {
    align-items: flex-start;
    margin-top: rem-calc(80);
  }

  .ct-error {
    flex-direction: column;
    display: flex;
  }

  .close-btn {
    position: absolute;
    top: .25rem;
    right: 1rem;
  }
  .ct-error-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 24rem;
  }
  .contact-support {
    color: $brand-blue-500;
    text-decoration: none;
  }
}
