$media-xs: 399px;
$media-sm: 599px;
$media-md: 899px;
$media-lg: 1199px;
$media-xl: 1535px;

$expanded-sidenav-width: 240px;
$expanded-sidenav-width-mobile: 283px;
$collapsed-sidenav-width: 64px;
$banner-height: 36px;
$header-height: 60px;
$document-header-height: 54px;

$mobile-header-height: 57px;
$mobile-filter-height: 45px;

$homepage-content-height: 70vh;

$base-z-index: 1;
$header-z-index: 1100; // MUI appBar
$sidenav-z-index: 1200; // MUI drawer
$side-menu-max-width: 340px;

$modal-body: 27.5rem;
