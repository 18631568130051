@import 'src/utils/styles/_imports';

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.ct-full-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .general-text,
  .user-query {
    text-align: center;

    @media (max-width: $media-sm) {
      max-width: rem-calc(300);
    }
  }

  .user-query {
    margin-top: 1.5rem;
    margin-bottom: rem-calc(97);
  }

  .loading-box {
    border: rem-calc(1) solid $gray-300;
    border-radius: rem-calc(10);
    padding: 1.125rem 1.25rem;
    width: 100%;
    max-width: rem-calc(533);
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;

    @media (max-width: $media-sm) {
      max-width: rem-calc(300);
    }

    &.opacity-6 {
      opacity: 0.6;
    }

    &.opacity-3 {
      opacity: 0.3;
    }

    .top-bar {
      width: rem-calc(458);
      height: rem-calc(19);
      margin-bottom: 0.75rem;

      @media (max-width: $media-sm) {
        width: rem-calc(300);
      }
    }

    .bottom-bar {
      width: rem-calc(240);
      height: rem-calc(19);

      @media (max-width: $media-sm) {
        width: rem-calc(140);
      }
    }

    .top-bar,
    .bottom-bar {
      background: $bright-blue-100;
      box-shadow: inset 0 -0.125rem 0 $bright-blue-500;
      background-image: linear-gradient(to right, $bright-blue-100 0%, $bright-blue-300 20%, $bright-blue-100 40%, $bright-blue-100 100%);
      background-repeat: no-repeat;
      background-size: rem-calc(800) rem-calc(104);
      border-radius: rem-calc(7);

      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
    }
  }
}
