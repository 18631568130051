@import 'src/utils/styles/_imports';

.saps-verify-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ct-hompage-header {
    padding: 1rem 1.5rem;
    display: flex;
    align-self: flex-start;

    @media (max-width: $media-sm) {
      align-self: center;
    }

    .casetext-logo-svg {
      min-height: rem-calc(30);
    }
  }
}
