@import 'src/utils/styles/_imports';

.saps-app {
  width: 100%;
  height: 100%;

  .saps-main {
    width: 100%;
    height: 100%;
    display: flex;
  }
}
