@import 'src/utils/styles/_imports';

.search-ed {
    display: contents;

    .search-ed-heading {
        .search-ed-matched-results {
            font-weight: rem-calc(400);
            max-width: rem-calc(587);
            text-align: center;
            margin-bottom: rem-calc(19.16);

            @media (max-width: $media-sm) {
                margin-bottom: rem-calc(18);
                padding-left: rem-calc(48);
                padding-right: rem-calc(48);
            }
        }
    }

    .ct-callout-box {
        @include ct-callout-box();
        margin: rem-calc(36) rem-calc(4) 0 rem-calc(4);
        padding: rem-calc(49) 2.5rem rem-calc(40) 2.5rem;
        text-align: center;

        .search-ed-callout-title {
            font-weight: rem-calc(400);
            justify-content: center;
            margin-bottom: rem-calc(19);

            @media (max-width: $media-sm) {
                margin-left: rem-calc(24);
                margin-right: rem-calc(24);
            }
        }

        .ct-callouts-sub {
            display: block;
            padding-left: rem-calc(21);
            padding-right: rem-calc(21);

            @media (max-width: $media-sm) {
                padding: 0;
            }
        }

        @media (max-width: $media-sm) {
            margin-top: rem-calc(37);
            padding: rem-calc(39) rem-calc(28) rem-calc(48) rem-calc(28);
        }
    }
}