@import 'src/utils/styles/_imports';

@mixin search-selected() {
  border-radius: 0.25rem;
  &.is-mobile {
    &.is-serp {
      width: 100vw;
    }
  }

  .ct-search-input-w-suggestions {
    border-radius: 0.625rem;
  }
}

.ct-search-input {
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.5rem rgba(33, 33, 33, 0.08);
  min-height: rem-calc(40);
  position: relative;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: 0 0.125rem 0.5rem rgba(33, 33, 33, 0.24);
  }

  &.is-mobile {
    &.is-serp {
      box-shadow: none;
    }
  }

  &.jx-open {
    bottom: 38vh;
    // Adjustment to fit apply jx button at very small sizes.
    @media (max-height: rem-calc(520)) {
      bottom: 20vh;
    }

    @media (max-width: $media-sm) {
      box-shadow: none;
    }
  }

  .float-above-input {
    position: relative;
    width: 100%;
    .ct-ps-popover {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      @media (max-width: $media-sm) {
        margin: 0.6rem 0;
      }
    }
  }

  .ct-search-input-w-suggestions {
    padding: 0.25rem 0.5rem 0 0.5rem;

    &.search-open,
    &.is-large {
      padding: rem-calc(5) rem-calc(9);
    }

    &.is-mobile {
      padding: 0;

      &.search-open,
      &.is-large {
        padding: rem-calc(1) rem-calc(1);
      }
    }
  }

  .ct-background-blocker {
    background: rgba(0,0,0,0.24);
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: $header-z-index - 1;
  }

  .ct-search-input-container {
    position: absolute;
    width: 100%;
    z-index: $header-z-index;

    &.is-mobile {
      &.is-serp {
        border-radius: 0;
      }
    }
        
    &.jx-open {
      background: $ct-search-white;
      @include search-selected();
      &.is-mobile {
        top: rem-calc(100);
        &.is-serp {
          top: 38vh;
        }
      }
      .ct-search-input-w-suggestions {
        border: none;
      }
    }

    &.search-open,
    &.is-large {
      @include search-selected();
      .ct-search-input-w-suggestions {
        border-bottom: rem-calc(1) solid $gray-300;
      }
    }

    .action {
      padding-left: 1rem;
      padding-right: 1rem;
      &:hover {
        background-color: $white;
      }
    }
    
    .ct-search-modal-actions {
      background: $white;
      border-top: rem-calc(1) solid $gray-300;
      bottom: 0;
      padding: 0.5rem;
      position: sticky;
    }

    .ct-search-input-w-suggestions {
      border-radius: 3rem;
      border: rem-calc(1) solid $gray-300;

      &.search-open,
      &.is-large {
        border: none;
      }

      &.is-serp {
        &.is-mobile {
          border: none;
          border-radius: 0;
          &:not(.search-open) {
            padding: 0.375rem 0;
          }
        }
      }
    }
  }

  .jx-modal {
    background: $white;
    max-height: 75vh;
    // Adjustment to fit apply jx button at very small sizes.
    @media(max-height: rem-calc(520)) {
      max-height: 40vh;
    }
    overflow: auto;
    padding: 1rem;
    &.is-mobile {
      max-height: 55vh;
    }
  }
}