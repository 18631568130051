@import 'src/utils/styles/_imports';

// TODO: Move `.filter-modal-header` into an a file for ContentTypeModal
.filter-modal-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;

  .modal-header-text {
    flex: 1;
  }
}

.ct-mobile-results-filters {
  padding: 0.25rem;
  border-bottom: rem-calc(1) solid $gray-300;
  position: sticky;
  top: $mobile-header-height;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 100;

  &.is-banner-open {
    top: $mobile-header-height + $banner-height;
  }

  &.filters-btn-container {
    display: flex;
    align-items: center;

    .popover-filters-container-space {
      flex-grow: 1;
    }

    span {
      font-size: 0.875rem;
    }
  }
}
