@import 'src/utils/styles/_imports';

.signin {
  display: contents;

  .signin-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem-calc(40);
    text-align: center;

    .heading-text {
      padding-bottom: .5rem;
    }
  }

  .signin-body {
    .input-field {
      margin-bottom: 1rem;

      .input {
        padding: 1rem;
      }
    }

    max-width: $modal-body;
    width: 100%;
  }

  .continue-button {
    &:disabled {
      background-color: $brand-blue-200;
      color: $white;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name-field {
    display: flex;
    flex-direction: row;
    max-width: $modal-body;
    column-gap: 1rem;

    &.is-mobile {
      flex-direction: column;
      column-gap: initial;
      width: 100%;
    }
  }

  .verification-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      margin: 0 1rem 0.5625rem 1rem;
    }

    .title {
      font-size: 2rem;
      line-height: 2.625rem;
      margin: 1rem;
      text-align: center;
      word-break: break-word;

      @media (max-width: $media-sm) {
        font-size: rem-calc(24);
        line-height: rem-calc(32);
      }
    }

    .byline {
      color: $ct-muted;
      text-align: center;
      line-height: rem-calc(24);

      @media (max-width: $media-sm) {
        font-size: rem-calc(14);
        line-height: rem-calc(22);
      }
    }

    .link-button {
      display: inline-block
    }
  }
}