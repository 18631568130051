@import 'src/utils/styles/_imports';

.ct-ps-popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.4rem;
  @media (max-width: $media-sm) {
    margin: 0.6rem 0.8rem;
  }
  .popover-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 0.8rem;
    padding-bottom: 1.7rem;
    @media (max-width: $media-sm) {
      padding: 1.5rem 0.8rem;
      padding-bottom: 1rem;
    }
    width: calc(100% - 0.8rem);
    background-color: $brand-blue-50;
    border-radius: .625rem;

    .header {
      max-width: 35rem;
      @media (max-width: $media-sm) {
        max-width: 18rem;
      }
      font-weight: 400;
      font-size: 1.5rem;
      @media (max-width: $media-sm) {
        font-size: 1rem;
      }
      text-align: center;
    }

    .example {
      color: $gray-800;
      padding: 1.125rem 1.437rem;
      padding-right: 3rem;
      margin-top: 2rem;
      @media (max-width: $media-sm) {
        margin-top: 1rem;
        padding-right: 3rem;
        width: calc(100% - 5rem);
        font-size: .8rem;
      }
      font-size: 1rem;
      background: rgba(179, 206, 230, 0.2);
      border-radius: 10px;
      width: calc(100% - 6rem);
      .desktop-example-copy {
        @media (max-width: $media-sm) {
          display: none;
        }
        margin-bottom: 1rem;
      }
      .mobile-example-copy {
        display: none;
        @media (max-width: $media-sm) {
          display: inline;
        }
      }
      .example-body {
        max-width: 40rem;
        font-style: italic;
      }
    }
  }
}
