@import 'src/utils/styles/_imports';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ct-search-results-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;

  .ct-teaser-container {
    margin-top: rem-calc(45);
  }

  .ct-search-results-content {
    display: flex;
    height: calc(100vh - #{$header-height});
    @media (max-width: $media-md) {
      height: 100%;
    }
    position: relative;
    
    .ct-search-results-container {
      height: calc(100vh - #{$header-height});
      @media (max-width: $media-sm) {
        height: 100%;
      }

      overflow-y: auto;
      flex: 1 1 calc(100% - #{$side-menu-max-width});
      overflow-y: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 2.5rem;
      box-sizing: border-box;
      display: flex;
      margin-left: 0;

      &.center-content {
        justify-content: center;
      }

      @media (max-width: $media-lg) {
        padding-left: 2rem;
        padding-right: 2rem;
        &.is-mobile {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      margin-left: calc((#{$collapsed-sidenav-width}/2) - 2rem);

      .ct-search-results-container-content {
        width: rem-calc(700);
        margin: 0;
      }

      .ct-results-header {
        margin-bottom: 0.75rem;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }

  .ct-search-result-header-action {
    font-weight: 400;
    .ct-search-result-header-action-endIcon {
      margin-left: rem-calc(6);
      margin-right: -1 * rem-calc(6);
    }
  }

  .search-results-list {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }

  .auth-alert {
    padding-top: 1rem
  }

  .resend-button {
    border: 0;
  }

  .desktop-sidebar-container {
    border-right: rem-calc(1) solid $gray-300;
    max-width: 16.5rem;
    height: 100%;
  }
}
