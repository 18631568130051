@import 'src/utils/styles/_imports';

.ct-search-result-container {
  margin-bottom: rem-calc(40);

  .ct-search-result-subheader,
  .ct-search-result-summary,
  .ct-result-snippets {
    margin-bottom: 1rem;
  }

  .ct-search-result-header-container{
    display: flex;
    align-items: center;

    .ct-search-result-header{
      h4 {
        display: flex;
        margin: 0;
        overflow-wrap: anywhere;
        .visibility-icon {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .ct-search-result-subheader {
    span:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .ct-highlightable {
    em {
      @include highlight-blue();
    }
  }

  .ct-search-result-summary {
    .summary-prefix-pmt {
      color: $gray-700;
      border-bottom: rem-calc(1) dashed $gray-400;
    }

    .ct-search-result-summary-icon-root {
      vertical-align: text-top;
      margin-left: rem-calc(2);
    }

    .ct-result-summary-snippet {
      line-height: 1.5;
    }
  }

  .ct-result-snippets {
    .snippets-pager-container {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .snippets-pager {
      display: flex;
      align-items: center;
    }

    .snippets-pager-buttons {
      margin-right: 1rem;
      box-shadow: 0 rem-calc(1) rem-calc(6) rgba(0, 0, 0, 0.07);

      button {
        padding-left: 0;
        padding-right: 0;
        min-width: rem-calc(37);
        border-color: $gray-300;
      }
    }

    .snippets-page-info {
      flex-grow: 1;
      text-align: right;
    }
    
    .result-snippet-content {
      @include break-long-words();
      font-size: 1rem;
      line-height: 1.5;
      padding: 0.25rem 0 0.25rem 1rem;
      border-left: rem-calc(1) solid $gray-300;
      border-radius: 0 rem-calc(4) rem-calc(4) 0;
      cursor: pointer;

      &:hover {
        background: $gray-50;
      }
    }

    .result-snippet-item {
      margin: 0;
      &:not(:last-child) {
        padding-bottom: 1rem
      }
    }
  }
}
